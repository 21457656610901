<template>
  <div style="max-height: 100vh">
    <div v-if="!register_login">
      <Menubar :model="menu">
        <template #start>
          <router-link to="/">
            <img
              alt="logo"
              src="./assets/layout/images/cloud-logo.svg"
              height="45px"
            />
          </router-link>
        </template>
        <template #end>
          <div style="text-align: center; display: table">
            <Button
              class="p-button-menubar-user-profile"
              @click="toggleProfileMenu"
            >
              <i class="pi pi-user" style="font-size: 24px" />
              <!-- <span style="display:table-cell; vertical-align:middle; margin: 0 5px">{{ user.name }}</span> -->
              <i class="pi pi-chevron-down" />
            </Button>

            <Menu ref="menu" :model="profileMenu" :popup="true" />
          </div>
        </template>
      </Menubar>
      <div
        class="layout-main"
        style="overflow-y: auto; overflow-x: hidden; max-height: 90vh"
      >
        <Toast position="bottom-right" />
        <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id"
          >{{ msg.content }}
        </Message>
        <router-view
          style="margin-bottom: 5em; min-height: 80vh"
          @afterPlot="onAfterPlot"
          @beforeData="getMessage"
        />
      </div>
    </div>
    <div v-else>
      <Toast />
      <div style="overflow-x: hidden">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
// import {mapGetters} from "vuex";
export default {
  data() {
    return {
      messages: [],
      register_login: false,
      menu: [
        { label: "Upload Files", icon: "fas fa-fw fa-file-upload", to: "/" },
        {
          label: "Reports",
          icon: "fas fa-fw fa-clipboard-list",
          items: [
            {
              label: "Cart Info",
              icon: "fas fa-fw fa-cart-arrow-down",
              to: "/reports/report-cart-info",
            },
            {
              label: "Unloads",
              icon: "fas fa-fw fa-truck-loading",
              to: "/reports/report-unloads",
            },
            {
              label: "Auger",
              icon: "fas fa-fw fa-check-double",
              to: "/reports/report-auger",
            },
            {
              label: "Hitch Loading",
              icon: "fas fa-fw fa-people-arrows",
              to: "/reports/report-hitch",
            },
            {
              label: "Coverage",
              icon: "fas fa-fw fa-calendar",
              to: "/reports/report-coverage",
            },
            {
              label: "Raw Log Viewer",
              icon: "fas fa-fw fa-chart-bar",
              to: "/reports/raw-log-viewer",
            },
            {
              label: "Debug",
              icon: "fas fa-light fa-bug",
              to: "/reports/report-debug",
            },
            {
              label: "Hours",
              icon: "fas fa-hourglass",
              to: "/reports/report-hours",
            },
          ],
        },
        {
          label: "Testing",
          icon: "fa solid fa-flask",
          items: [
            {
                label: "Field Data",
                icon: "fas fa-fw fa-chart-bar",
                to: "/testing/DAQ",
            },
            {
                label: "Data Explorer",
                icon: "fas fa-binoculars",
                to: "/testing/Explorer",
            },
            {
              label: "Database Authentication",
              icon: "fas fa-fw fa-cloud",
              to: "/testing/DB-auth",
            },
            {
              label: "Harrow Data",
              icon: "fas fa-ruler-combined",
              to: "/testing/harrow-data",
            },
          ]
        },
      ],
      profileMenu: [
        {
          label: "Account Settings",
          icon: "pi pi-fw pi-cog",
          to: "/account-settings",
        },
        {
          label: "Logout",
          icon: "pi pi-fw pi-power-off",
          to: "/logout",
        },
      ],
    };
  },
  mounted() {
    Echo.channel(this.userId()).listen(".UploadStatus", (e) => {
      this.getMessage(e);
    });
  },
  watch: {
    $route() {
      switch (this.$route.name) {
        case "loginPage":
          this.register_login = true;
          break;
          case "loginPage2":
          this.register_login = true;
          break;
        default:
          this.register_login = false;
      }
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    toggleProfileMenu(event) {
      this.$refs.menu.toggle(event);
    },
    onAfterPlot(messageId) {
      for (let i = 0; i < this.messages.length; i++) {
        if (this.messages[i].messageId === messageId) {
          this.messages.splice(i, 1);
          break;
        }
      }
    },
    getMessage(e) {
      let found = false;
      for (let i = 0; i < this.messages.length; i++) {
        if (this.messages[i].messageId === e.messageId) {
          this.messages.splice(i, 1);
          this.messages.push({
            severity: e.severity,
            content: e.content,
            messageId: e.messageId,
          });
          found = true;
          break;
        }
      }
      if (!found) {
        this.messages.push({
          severity: e.severity,
          content: e.content,
          messageId: e.messageId,
        });
      }
    },
    userId() {
      return localStorage.getItem("_userId");
    },
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
