export default {
    namespaced: true,
    state: {
        unloadsSort: [],
        binsSort: [],
        cropsSort: [],
        truckSort: [],
        combineSort: [],
        dataSort: [],
    },
    getters: {
        unloadsSort: state => state.unloadsSort,
        binsSort: state => state.binsSort,
        cropsSort: state => state.cropsSort,
        truckSort: state => state.truckSort,
        combineSort:  state => state.combineSort,
        dataSort: state => state.dataSort
    },
    mutations: {
        setUnloadsSort: (state, unloadsSort) => state.unloadsSort = unloadsSort,
        setBinsSort: (state, binsSort) => state.binsSort = binsSort,
        setCropsSort: (state, cropsSort) => state.cropsSort = cropsSort,
        setTruckSort: (state, truckSort) => state.truckSort = truckSort,
        setCombineSort: (state, combineSort) => state.combineSort = combineSort,
        setDataSort: (state, dataSort) => state.dataSort = dataSort
    },
    actions: {}
}
