import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from "./store";
import './primeVue';
import './thirdPartyImports';

import './assets/layout/layout.scss';

import {dom} from '@fortawesome/fontawesome-svg-core'

dom.watch()

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
