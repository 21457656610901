import axios from "axios";

const baseUrl = process.env.VUE_APP_LUMOS_API_BASE_URL;
const authService = {
    loggedIn() {
        return !!(localStorage.getItem('_token'))
    },
    setToken(token) {
        localStorage.setItem('_token', token)
    },
    setUserId(userId) {
        localStorage.setItem('_userId', userId)
    },
    login() {
        window.location = baseUrl + '/web/login/azure';
    },
    logout() {
        localStorage.removeItem('_token');
        localStorage.removeItem('_userId');
    },
    login2(login) {
        return axios.post(baseUrl + '/api/auth/login', login.toJson(), this.getHeader())
            .then(res => {
                localStorage.setItem('_token', res.data.api_token)
                localStorage.setItem('_userId', res.data.userId)
            })
    },
    getHeader() {
        return {
            'headers': {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
        }
    },
}

export default authService
