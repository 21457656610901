import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import {library, dom} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {Plotly} from 'vue-plotly'
import vueNumeralFilterInstaller from 'vue-numeral-filter';

Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });
Vue.use(VueAxios, axios)
library.add(fas)
dom.watch()
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('Plotly', Plotly)

import Echo from "laravel-echo"

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_KEY,
    wsHost: process.env.VUE_APP_PUSHER_HOST,
    wsPort: process.env.VUE_APP_PUSHER_PORT,
    wssPort: process.env.VUE_APP_PUSHER_PORT,
    enabledTransports: ['wss'],
    forceTLS: true,
    disableStats: true,
});
