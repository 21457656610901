export default class Filter {
    constructor(
        serialNumbers = [],
        deviceIds = [],
        testIds = [],
        dataTypes = [],
        canAddresses = [],
        signalNames = [],
        startDate = null,
        endDate = null,
        minValue = null,
        maxValue = null,
        unit = 'kg',
    ) {
        this.serialNumbers = serialNumbers
        this.deviceIds = deviceIds
        this.testIds = testIds
        this.dataTypes = dataTypes
        this.canAddresses = canAddresses
        this.signalNames = signalNames
        this.minValue = minValue
        this.maxValue = maxValue
        this.startDate = startDate
        this.endDate = endDate
        this.unit = unit
    }


    /**
     * Returns an array.  If the input array is empty, then the output array will be empty.
     * Otherwise, return a list of length 1 with a query-string-compatible object in it.
     */
    static queryStringObjFromArr(arr, name) {
        if (!Array.isArray(arr)) {
            throw ("Argument should be an array")
        }
        if (arr.length === 0) {
            return []
        }
        return [{
            'key': name,
            'value': arr.join(','),
        }]
    }

    static queryStringObjFromStrDatetime(dt, name) {
        if (dt === null || dt === "") {
            return []
        }
        return [{
            'key': name,
            'value': new Date(Date.parse(dt)).toISOString().replace('T', ' ')
        }]
    }

    static queryStringObjFromNullableNumber(value, name) {
        if (value === null) {
            return []
        }
        return [{
            'key': name,
            'value': value
        }]
    }

    static getQueryStringFilters(filter) {
        let result = Filter.queryStringObjFromArr(filter.serialNumbers, 'serialNumbers')
            .concat(Filter.queryStringObjFromArr(filter.deviceIds, 'deviceIds'))
            .concat(Filter.queryStringObjFromArr(filter.testIds, 'testIds'))
            .concat(Filter.queryStringObjFromArr(filter.dataTypes, 'dataTypes'))
            .concat(Filter.queryStringObjFromArr(filter.canAddresses, 'canAddresses'))
            .concat(Filter.queryStringObjFromArr(filter.signalNames, 'signalNames'))
            .concat(Filter.queryStringObjFromStrDatetime(filter.startDate, 'startDate'))
            .concat(Filter.queryStringObjFromStrDatetime(filter.endDate, 'endDate'))
            .concat(Filter.queryStringObjFromNullableNumber(filter.minValue, 'minValue'))
            .concat(Filter.queryStringObjFromNullableNumber(filter.maxValue, 'maxValue'))
        result.push({'key': 'unit', 'value': filter.unit})
        return result
    }

    static mutations() {
        return {
            setFilter: (state, filter) => state.filter = filter,
            setStartDate: (state, newValue) => state.filter.startDate = newValue,
            setEndDate: (state, newValue) => state.filter.endDate = newValue,
            setMinValue: (state, newValue) => state.filter.minValue = newValue,
            setMaxValue: (state, newValue) => state.filter.maxValue = newValue,
        }
    }
}
