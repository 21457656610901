import Vue from 'vue'
import Vuex from 'vuex'
import filter from "@/store/filter";
import userSortStore from "@/store/userSortStore";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)
const store = new Vuex.Store({
        namespace: true,
        modules: {
            userSortStore, filter
        },
        plugins: [createPersistedState()],
    }
)
export default store