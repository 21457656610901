import Filter from "@/models/filter";
export default {
    namespaced: true,
    state: {
        filter: new Filter
    },
    getters: {
      filter: state => state.filter
    },
    mutations: {
        ...Filter.mutations()
    },
    actions: {}
}
