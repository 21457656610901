import Vue from 'vue';
import Router from 'vue-router';
import authService from "./services/authService";
Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: '/',
            name: 'upload',
            meta: {requiresAuth: true},
            component: () => import('./components/UploadFiles')
        },
        //Reports
        {
            path: '/reports/report-cart-info',
            name: 'report-cart-info',
            meta: {requiresAuth: true},
            component: () => import('./components/ReportCartInfo')
        },
        {
            path: '/reports/report-unloads',
            name: 'report-unloads',
            meta: {requiresAuth: true},
            component: () => import('./components/ReportUnloads')
        },
        {
            path: '/reports/report-auger',
            name: 'report-auger',
            meta: {requiresAuth: true},
            component: () => import('./components/ReportAuger')
        },
        {
            path: '/reports/report-hitch',
            name: 'report-hitch',
            meta: {requiresAuth: true},
            component: () => import('./components/ReportHitchLoading')
        },
        {
            path: '/reports/report-coverage',
            name: 'report-coverage',
            meta: {requiresAuth: true},
            component: () => import('./components/ReportCoverage')
        },
        {
            path: '/reports/raw-log-viewer',
            name: 'raw-log-viewer',
            meta: {requiresAuth: true},
            component: () => import('./components/RawLogViewer')
        },
        {
            path: '/reports/report-debug',
            name: 'report-debug',
            meta: {requiresAuth: true},
            component: () => import('./components/ReportDebug')
        },
        {
            path: '/reports/report-hours',
            name: 'report-hours',
            meta: {requiresAuth: true}, //TODO: [LU-36] set requiresAuth to true;
            component: () => import('./components/ReportHours')
        },
        {
            name: 'loginPage',
            path: '/login',
            meta: {requiresAuth: false},
            component: () => import('./components/LoginPage.vue')
        },
        {
            name: 'loginPage2',
            path: '/login2',
            meta: {requiresAuth: false},
            component: () => import('./components/Login.vue')
        },
        {
            name: 'logout',
            path: '/logout',
            meta: {requiresAuth: true},
        },
        //Testing Data
        {
            path: '/testing/DAQ',
            name: 'DAQ',
            meta: {requiresAuth: true},
            component: () => import('./components/DAQ')
        },
        {
            path: '/testing/Explorer',
            name: 'Explorer',
            meta: {requiresAuth: true},
            component: () => import('./components/Explorer.vue')
        },
        {
            path: '/testing/DB-auth',
            name: 'DB-auth',
            meta: {requiresAuth: true},
            component: () => import('./components/DB-auth')
        },
        {
            path: '/testing/harrow-data',
            name: 'HarrowData',
            meta: {requiresAuth: true},
            component: () => import('./components/HarrowData.vue')
        },
     ],
    scrollBehavior() {
        return {x: 0, y: 0};
    }
});
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    try {
        if (requiresAuth && to.path === '/logout') {
            authService.logout()
            next('/login')
        } else {
            if (requiresAuth && !authService.loggedIn()) {
                next('/login')
            } else {
                next()
            }
        }
    } catch
        (e) {
        console.log(e)
        next()
    }
})
export default router;
